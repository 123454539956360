// Clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: ' '; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// Linear-Gradient
@mixin linearGradient($left, $right) {
  background: $left; /* Old browsers */
  background: -moz-linear-gradient(right, $left 0%, $right 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $left),
    color-stop(100%, $right)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    right,
    $left 0%,
    $right 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    right,
    $left 0%,
    $right 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(right, $left 0%, $right 100%); /* IE10+ */
  background: linear-gradient(to right, $left 0%, $right 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

//transition
@mixin transition($transtion...) {
  -webkit-transition: $transtion;
  -moz-transition: $transtion;
  -ms-transition: $transtion;
  -o-transition: $transtion;
  transition: $transtion;
}

// Opacity
@mixin opacity($value) {
  $IEValue: $value * 100;
  opacity: $value;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' + $IEValue +
    ')';
  filter: alpha(opacity=$IEValue);
}

//rwd
// @media媒體查詢
$desktop-media: 1000px;
$desktop-media-less: 999px;
$pad-media: 801px;
$pad-media-less: 800px;
$phone-media: 480px;
$phone-media-less: 479px;
$mini-phone-media-less: 360px;
//desktop ~up
@mixin pc-width() {
  @media all and (min-width: $desktop-media) {
    @content;
  }
}
//desktop ~
@mixin pc-width-less() {
  @media all and (max-width: $desktop-media-less) {
    @content;
  }
}

//pad ~ desktop
@mixin pad-pc-width() {
  @media all and (min-width: $pad-media) and (max-width: $desktop-media) {
    @content;
  }
}

// pad ~
@mixin pad-width-less() {
  @media all and (max-width: $pad-media-less) {
    @content;
  }
}

//phone ~
@mixin phone-width-less() {
  @media all and (max-width: $phone-media-less) {
    @content;
  }
}

//mini-phone ~
@mixin mini-phone-width-less() {
  @media all and (max-width: $mini-phone-media-less) {
    @content;
  }
}
