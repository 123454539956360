@import 'reset';
@import 'mixin';
//Font
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

//基本元素
html {
  height: 100%;
  font-size: 100%;
}
body {
  height: 100%;
  font-family: 'Arimo', 'Noto Sans TC', 'Microsoft YaHei', 'Microsoft JhengHei',
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  background: #fff;
}
* {
  position: relative;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}
a,
a:active,
a:focus,
a:hover {
  text-decoration: none;
  color: inherit;
}
.text-italic {
  font-style: italic;
}
.container {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @include pc-width-less {
    width: 100%;
  }
}
.main {
  padding-top: 90px;
}
.footer {
  width: 100%;
  color: #151515;
  background: #fff;
  text-align: center;
  padding: 2rem;
}
.row {
  margin: 4rem 0;
  @include pc-width-less {
    padding: 0 2rem;
  }
  @include mini-phone-width-less {
    padding: 0 1rem;
  }
}
.btn-wrap {
  padding: 2rem 0;
  text-align: center;
}
.btn {
  background-color: #151515;
  color: #ffffff;
  padding: 10px 24px;
  text-align: center;
  cursor: pointer;
  border: none;
  white-space: nowrap;
  img {
    margin: 0 !important;
    width: auto !important;
    padding-right: 1rem;
  }
  &:hover {
    color: #ffffff;
    background-color: #353535;
  }
}
//Title
.title-wrap {
  text-align: center;
  position: relative;
  h3 {
    font-size: 1.75rem;
    background: #fff;
    z-index: 10;
    display: inline-block;
    padding: 0 2rem;
    font-weight: bold;
  }
  span {
    font-size: 1.25rem;
    display: block;
    font-weight: 500;
  }
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #151515;
    position: absolute;
    top: 50%;
    left: 0;
  }
}
.detail-title {
  border-top: 1px solid #151515;
  border-bottom: 1px solid #151515;
  padding: 1.5rem 0;
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }
}
.subtitle{
  border-bottom: 1px solid #151515;
  padding: 0.5rem 0;
  width: fit-content;
  margin: 2rem auto 0;
  h4{
    font-size: 1.25rem;
    font-weight: bold;
  }
}
//header
.header {
  position: fixed;
  background: #f6f6f6;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 10px 12px 24px 6px rgba(0, 0, 0, 0.08);
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    @include pc-width-less {
      padding: 0 2rem;
    }
    @include phone-width-less {
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .logo {
    width: 80px;
    height: 48px;
    display: block;
    background: url(../images/logo.svg) no-repeat;
    background-size: cover;
    text-indent: -9999px;
    @include phone-width-less {
      margin-left: 2rem;
      margin-top: 1rem;
    }
  }
  .nav {
    display: flex;
    flex-direction: row;
    @include phone-width-less {
      width: 100%;
      position: absolute;
      top: -200px;
      flex-direction: column;
      text-align: center;
      justify-content: center;
    }
    &.active {
      @include phone-width-less {
        height: 100vh;
        top: 0;
        background: #f6f6f6;
        padding: 2rem;
        overflow: hidden;
        a {
          width: 100%;
          height: auto;
          padding: 1rem;
        }
      }
    }
    a {
      display: block;
      width: 80px;
      height: 40px;
      line-height: 40px;
      text-decoration: none;
      color: #151515;
    }
  }
  .btn-menu {
    position: absolute;
    top: 2rem;
    right: 3rem;
    display: none;
    width: 32px;
    height: 32px;
    cursor: pointer;
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
    @include phone-width-less {
      display: block;
    }
    &.times {
      span {
        opacity: 1;
        transform: rotate(45deg) translate(-10px, -20px);
        &:nth-last-child(3) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }
        &:nth-last-child(2) {
          transform: rotate(-45deg) translate(-1px, 11px);
        }
      }
    }
    span {
      display: block;
      width: 32px;
      height: 3px;
      margin-bottom: 7px;
      position: relative;
      background: #151515;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        opacity 0.55s ease;
      &:first-child {
        transform-origin: 0% 0%;
      }
      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
    }
  }
}

//banner
.banner {
  width: 100%;
  height: 480px;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .quotes {
    position: relative;
    h2 {
      font-style: italic;
      font-weight: bold;
      @include phone-width-less {
        font-size: 1.5rem;
      }
    }
    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 18px;
      background: url(../images/quote-before.svg) no-repeat;
      position: absolute;
      left: -40px;
      @include phone-width-less {
        left: -30px;
      }
    }
    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 18px;
      background: url(../images/quote-after.svg) no-repeat;
      position: absolute;
      right: -40px;
      @include phone-width-less {
        right: -30px;
      }
    }
  }
  .quotes-name {
    width: 320px;
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-left: 2rem;
    @include phone-width-less {
      width: 300px;
    }
    &::before {
      content: '';
      display: block;
      width: 200px;
      height: 1px;
      background: #151515;
      margin-right: 1rem;
      @include phone-width-less {
        width: 150px;
      }
    }
  }
}
//feature
.feature-wrap {
  .feature-head {
    display: flex;
    justify-content: space-around;
    @include phone-width-less {
      flex-direction: column;
      align-items: center;
    }
  }
  .feature-body {
    margin-top: 4rem;
    p {
      margin-bottom: 1rem;
    }
  }
}
.feature-item {
  text-align: center;
  @include phone-width-less {
    margin-bottom: 4rem;
  }
  .circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #e5e5e5;
    text-align: center;
    line-height: 165px;
    margin-bottom: 1rem;
  }
  h3 {
    font-weight: bold;
  }
}

//work
.work-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 4rem;
}
.work-item {
  width: 380px;
  height: 340px;
  background: #fff;
  box-shadow: 10px 12px 24px 6px rgba(0, 0, 0, 0.08);
  margin-top: 5rem;
  @include transition(all 0.5s ease);
  &:hover {
    transform: scale(1.03);
  }
  a {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
  }
  @include phone-width-less {
    width: 300px;
    height: 290px;
  }
  @include mini-phone-width-less {
    width: 100%;
    height: 280px;
  }
  &:nth-child(2n) {
    margin-top: 10rem;
    @include phone-width-less {
      margin-top: 5rem;
    }
  }
  img {
    width: 100%;
  }
  .work-info {
    padding: 24px;
    h3 {
      font-weight: bold;
    }
    p {
      color: #828282;
      line-height: 2;
    }
  }
  .label {
    background: #151515;
    color: #fff;
    font-weight: 500;
    display: inline-block;
    padding: 0 1rem;
    line-height: 2;
    position: absolute;
    bottom: 85px;
    left: 24px;
  }
}
.detail-wrap {
  .detail-content {
    padding: 2rem 0;
    img {
      width: 100%;
      margin: 4rem 0;
      @include phone-width-less {
        margin: 2rem 0;
      }
    }
    p {
      width: 70%;
      margin: 4rem auto;
      font-weight: 500;
      text-align: center;
      @include phone-width-less {
        width: 90%;
      }
    }
    .subtext{
      width: 50%;
      margin: 1rem auto 0;
      @include phone-width-less {
        width: 90%;
      }
    }
    .title-wrap {
      margin-top: 2rem;
    }
    .skills {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 2rem;
      @include phone-width-less {
        flex-direction: column;
        margin-top: 0;
      }
      li::before {
        content: '－';
        margin-left: 2rem;
        margin-right: 4px;
        @include phone-width-less {
          margin-left: 0;
        }
      }
    }
  }
}
//about
.about-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;
  @include pc-width-less {
    flex-direction: column;
  }
  img {
    width: 200px;
    height: 200px;
    margin-right: 4rem;
    @include pc-width-less {
      margin-right: 0;
      margin-bottom: 4rem;
    }
  }
  .about-body {
    display: flex;
    flex-direction: column;
    @include pc-width-less {
      align-items: center;
    }
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
      @include pc-width-less {
        margin-bottom: 2rem;
      }
    }
    ul {
      list-style: disc;
      margin-left: 1.5rem;
    }
  }
}
.about-info {
  display: flex;
  flex-direction: row;
  margin-top: 8rem;
  @include pc-width-less {
    flex-direction: column;
    margin-top: 4rem;
  }
  .column {
    flex: 1;
  }
  .item {
    text-align: center;
    margin-bottom: 2rem;
    h3 {
      font-size: 1.125rem;
      font-weight: bold;
      border-bottom: 1px solid #151515;
      display: inline-block;
      padding: 0.5rem;
      margin-bottom: 1rem;
    }
    a {
      &:hover {
        @include opacity(0.8);
      }
    }
    li {
      line-height: 2;
    }
  }
}
//videos
.vbox{ padding: 1rem;}
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 比例（视频宽度/高度比例） */
  margin-top: 2rem;
  height: 0;
  overflow: hidden;
  border: 10px #333131 solid;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}